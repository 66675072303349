import React from "react"
import Layout from "./../../components/layout"
import SEO from "./../../components/seo"
import Img from "gatsby-image"
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag"


//import css
import "./../../styles/free-tools.css"
import "./../../styles/products.css"
//import components
import GetStartedBanner from "./../../components/get-started-footer"

//import icons
import WebsiteUptimeIcon from "./../../assets/icons/svg/website-uptime-icon.svg"
import SSLCertIcon from "./../../assets/icons/svg/ssl-cert-icon.svg"
import TLSCertIcon from "./../../assets/icons/svg/tls-cert-icon.svg"
import SLACalIcon from "./../../assets/icons/svg/sla-cal-icon.svg"
import CronExpressionIcon from "./../../assets/icons/svg/cron-expression-generator-icon.svg"

const Freetools = ({ data }) => (
  <Layout>
    <SEO
      title="Odown Free Tools"
      description="free tools"
      pathname={`/free-tools/`}
       image={{
        src: "/free-tools.jpg",
        width: 1600,
        height: 800,
      }}
    />
    {/* HEADER  */}
    <HeadingSection />
    {/*List of Tools*/}
    <ToolSection />
    {/* Get started banner */}
    <GetStartedBanner />
  </Layout>
)

/**
 *
 * @param {*} param0
 * @returns
 */
const HeadingSection = ({ data }) => {
  return (
    <div className="u-features freetools-hero">
      <div className="container">
        <h1 className="u-features__heading-title">
        Free Tools for Developers
        </h1>
        <div className="FreeTool-heading__description">
        Discover powerful network, DevOps, and Site Reliability Engineering  tools to troubleshoot and optimize performance with Odown. Boost efficiency and reliability effortlessly with our free tools.        </div>
      </div>
      <div className="FreeTool-heading__button">
        <OutboundLink
          className="btn-primary"
          eventCategory="Launch the Odown App"
          eventAction="Click"
          eventLabel="Goto signup"
          href="https://app.odown.io/signup"
        >
          Trial Odown free for 14 days
        </OutboundLink>
      </div>
    </div>
  )
}
const ToolSection = () => {
    return (
        <div className="tool-sections">
        <div className="container">
          <div className="tool-row">
            {/* Website Uptime Test */}
              <Link to="/free-tools/website-uptime/" className="tool-card" style={{ textDecoration: 'none', color: 'inherit' }}>
              <div className="icon-text-container-website">
              <WebsiteUptimeIcon />
            <h3>Website Uptime Checker</h3>
            </div>
            <p>Check your website availability in different regions.</p>
          </Link>
          <Link to="/free-tools/ssl-checker/" className="tool-card" style={{ textDecoration: 'none', color: 'inherit' }}>
          <div className="icon-text-container">
                <SSLCertIcon />
            <h3>SSL Certificate Checker</h3>
            </div>
            <p>Check if your domain's SSL certificate is valid. Protect your website from phishing attacks.</p>
            </Link>
         
            <Link to="/free-tools/tls-checker/" className="tool-card" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="icon-text-container">
                <TLSCertIcon />
            <h3>TLS Security Checker</h3>
            </div>
            <p>Test your website's HTTPS security strength now. This Odown tool analyzes TLS versions and encryption to help protect your site.</p>
          </Link>
           
          <Link to="/free-tools/sla-calculator/" className="tool-card" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="icon-text-container">
                <SLACalIcon />
            <h3>SLA and Uptime Calculator</h3>
            </div>
            <p>Make sure you’re getting the best uptime from your vendor.</p>
          </Link>

          <Link to="/free-tools/cron-expression-generator/" className="tool-card" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="icon-text-container">
                <CronExpressionIcon />
            <h3>Cron Expression Generator</h3>
            </div>
            <p>Just tell us the time you’d like the job to run,
            and our tool will convert your instructions into a valid cron expression.</p>
          </Link>
          
            </div>
          </div>
        </div>      
  );
};    

export default Freetools
